<script setup>
import { onMounted, ref, defineOptions } from 'vue'
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue'
import UserScope from '@/components/mixins/UserScope.js'
import TranslationMixin from '@/components/quote/presentation/languages/TranslationMixin.js'
import Checkbox from '@/components/ui/Checkbox.vue'

defineOptions({
  mixins: [UserScope, TranslationMixin]
})

const checked = ref(0)
const loading = ref(1)
const showCheckbox = ref(0)

onMounted(async () => {
  loading.value = 0
  await c.throttle(() => {}, { delay: 2000 })
  showCheckbox.value = 1
})
</script>

<template>
  <div v-if="!checked" class="absolute inset-0 flex justify-center items-center">
    <div class="max-w-screen-md flex justify-center items-center">
      <div class="flex flex-col gap-20">
        <div class="flex flex-col gap-4 items-center">
          <div class="text-2xl font-medium">
            {{ l('Are you a human?') }} <font-awesome-icon icon="robot" />
          </div>

          <p>{{ l('Please confirm that you are a human by clicking the checkbox below!') }}</p>

          <div
            class="rounded-sm border-2 border-blue-print p-10 flex justify-center items-center gap-1"
          >
            <div class="font-medium">
              {{ l('Click checkbox to continue with your request') }}
            </div>

            <div class="flex justify-center items-center gap-1">
              <font-awesome-icon icon="arrow-right" />
              <LoadingIndicator v-if="!showCheckbox" />
              <Checkbox class="info inline" v-else v-model="checked" />
            </div>
          </div>
        </div>

        <div class="flex-col flex gap-2">
          <div class="text-lg font-medium">{{ l('Why do we do this?') }}</div>
          <p>
            {{
              l(
                'The web is full of bots and spiders that fill out forms automatically. Most forms will receive on average 10-15 per day.  Some can receive as many as 10-15 per minute. Having a lot of junk requests hurts our ability to respond to real requests and causes a lot of extra work to delete. We appreciate your help in fighting the spam!'
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <slot v-else></slot>
</template>

<style scoped lang="scss"></style>
