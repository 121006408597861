<template>
  <IsHuman>
    <scroll-container
      class="flex justify-center bg-flame-white"
      style="width: 100%"
      containerInnerStyle="display: flex; flex-direction: row; justify-content: center; align-items: flex-start; width: 100%;"
    >
      <div class="lead-form-container">
        <!-- FORM HAS BEEN SUBMITTED -->
        <div class="success-container" v-if="submitted">
          <font-awesome-icon class="check" icon="circle-check" />
          <h3 class="mb-3">{{ l('Submission Successful') }}</h3>
          <div>
            {{ l('Thank you for your submission! We will get back to you as soon as possible') }}.
          </div>
        </div>

        <!-- FORM IS LOADING -->
        <spinner v-else-if="formLoading" :loading="1" size="2em" />

        <!-- FORM BODY -->
        <div v-else-if="companyExists" class="lead-form-body">
          <img v-if="companyLogo" :src="companyLogo" style="max-width: 300px; max-height: 150px" />
          <div v-else class="text-[25px] font-bold">{{ companyName }}</div>
          <div class="lead-form-title">{{ leadFormName }}</div>
          <div
            class="field-section-container"
            v-for="field in formFields"
            :key="formFieldsData[field].label"
          >
            <field-section class="field-section">
              <span>{{ l(formFieldsData[field].label) }}</span>
              <field
                v-model="responseObject[field]"
                :placeholder="formFieldsData[field].desc"
                :type="formFieldsData[field].type"
                :ref="field"
                :disabled="submitLoading"
              />
            </field-section>
          </div>
          <div class="button-container">
            <Btn
              class="submit-button"
              text="Submit"
              :disabled="submitLoading"
              @click="submitLeadForm"
            />
          </div>
        </div>

        <!-- FORM NOT FOUND -->
        <div v-else class="error-message">
          <h3 class="mb-3">{{ l('Could not find form') }}</h3>
          <div>{{ l('This form does not exist. Please double check the url') }}.</div>
        </div>
      </div>
    </scroll-container>
  </IsHuman>
</template>

<script>
import FieldSection from '@/components/ui/fields/FieldSection.vue'
import useTranslation from '@/components/composables/Translation'
import IsHuman from '@/components/ui/IsHuman.vue'

export default {
  name: 'LeadForm',
  mixins: [],
  setup() {
    const { l } = useTranslation()

    return {
      l
    }
  },
  data() {
    return {
      leadRotationId: '',
      companyName: '',
      companyLogo: '',
      leadFormName: '',
      formFields: [],
      submissionUrl: '',
      formFieldsData: {},
      responseObject: {},
      formLoading: false,
      submitLoading: false,
      submitted: false,
      companyExists: true
    }
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.session.isLoggedIn
    },
    isCompanyScoped() {
      return this.$store.state.session.scope.company
    },
    companyId() {
      return this.$route.params.id
    },
    signature() {
      if (this.submissionUrl) return this.submissionUrl.split('/')[4]
      return false
    }
  },
  methods: {
    async getLeadForm() {
      this.formLoading = true
      let payload
      try {
        const response = await this.$store.dispatch('ajax', {
          path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/getCompanyNameAndLogo`,
          data: { company_id: this.companyId }
        })
        payload = response.payload
        if (!payload || !payload.company_name) throw new Error('No company found.')
      } catch (e) {
        this.companyExists = false
      }
      this.formLoading = false
      return payload
    },

    async createResponseObject() {
      this.formFields.forEach((field) => {
        this.responseObject[field] = ''
      })
    },

    async submitLeadForm() {
      this.submitLoading = true
      const data = {
        ...this.responseObject,
        company_id: this.companyId,
        signature: this.signature
      }
      try {
        const { object } = await this.$store.dispatch('ajax', {
          path: '/lead_rotation/finalizeLeadWithCompanyId',
          data
        })

        const received = object.received
        if (received.success_url && !received.success_url.endsWith('/pub/leadReceived')) {
          this.$store.dispatch('openLink', {
            url: received.success_url,
            newWindow: false
          })
        } else {
          this.submitted = true
        }
      } catch (e) {
        this.$store.dispatch('alert', { error: true, message: e.message }, { root: true })
      }
      this.submitLoading = false
    }
  },
  async mounted() {
    this.formFieldsData = await this.$store.dispatch('LeadRotation/getFormFieldsData')
    const companyDetails = await this.getLeadForm()
    this.companyName = companyDetails.company_name
    const file = companyDetails.company_logo_file_id
    if (file) this.companyLogo = `${import.meta.env.VITE_BASE_FILE_URL}file/view/${file}`
    this.leadFormName = `${this.companyName}`
    this.formFields = await this.$store.dispatch('LeadRotation/getDefaultFormFields')
    await this.createResponseObject()
  },
  components: {
    FieldSection,
    IsHuman
  }
}
</script>

<style lang="scss" scoped>
.lead-form-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  margin: 50px 0px;
  background-color: white;

  @media (max-width: 480px) {
    padding: 50px 0px;
    margin-top: 0px;
  }

  .success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .check {
      font-size: 5em;
      color: #68bf66;
      margin-bottom: 50px;
    }
  }

  .lead-form-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .lead-form-title {
      font-size: 30px;
      font-weight: 700;
      margin: 50px 0px;
      text-align: center;
    }

    .field-section-container {
      background-color: white;
      width: 100%;

      .field-section {
        background-color: white;
      }
    }

    .button-container {
      margin-top: 50px;
      width: 100%;
      padding: 0em 0.6em;

      .submit-button {
        width: 100%;
        background-color: black;
        color: white;
        border: 1px solid black;
      }
    }
  }

  .error-message {
    text-align: center;
  }
}
</style>
